import React, { useEffect, useState, createContext, useContext } from "react";

import io from "socket.io-client"

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState()
  
    useEffect(() => {
      const soc = io("https://ws-docs.jamesz.dev", {
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      })
      // const soc = io("https://ws-docs.jamesz.dev")
      console.log(soc)
      setSocket(soc)
      console.log("[WS] Connected to the WebSocket.")
  
      return () => {
        console.log("[WS] Disconnecting from WebSocket.")
        soc.disconnect()
      }
    }, [])

  
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
}