import React, { useEffect, useCallback, useState } from 'react'
import Quill from "quill"
import "quill/dist/quill.snow.css"
import { useParams, useNavigate } from "react-router-dom"
import { useSocket } from './SocketContext'

import EditorToolbar from "./ToolBar";

const SAVE_INTERVAL_MS = 2000
const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["image", "blockquote", "code-block"],
  ["clean"],
]

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

export default function TextEditor({ docName, setDocName }) {
    const { id: documentId} = useParams()
    
    const socket = useSocket();
    const [quill, setQuill] = useState()

    const [loadedDoc, setDocument] = useState()

    const navigate = useNavigate()

    useEffect(() => {
      if (socket == null || quill == null) return

      socket.once("load-document", document => {
        quill.setContents(document.data)
        quill.enable()
        setDocName(document.name)
        setDocument(document)
      })


      socket.emit("get-document", documentId)
    }, [socket, quill, documentId])

    useEffect(() => {
      if (socket == null || quill == null) return

      const interval = setInterval(() => {
        socket.emit("save-document", quill.getContents())
      }, SAVE_INTERVAL_MS)

      return () => {
        clearInterval(interval)
      }
    }, [socket, quill])

    useEffect(() => {
      if (socket && loadedDoc) {
        if (loadedDoc.name === docName || docName === null) return
        socket.emit("name-change", docName)
      }
    }, [socket, quill, docName, loadedDoc])

    useEffect(() => {
      if (socket == null || quill == null) return

      const handler = delta => {
        quill.updateContents(delta)
      }

      socket.on("receive-changes", handler)

      socket.on("error", data => {
        navigate(`/error?err=${data}`, { replace: true })
      })

      const handleSave = doc => {
        if (!doc) return
        setDocName(doc)
      }
      socket.on("name-changed", handleSave)

      return () => {
        socket.off("receive-changes", handler)
        socket.off("name-changed", handleSave)
      }

    }, [socket, quill])

    useEffect(() => {
      if (socket == null || quill == null) return

      const handler = (delta, oldDelta, source) => {
        if (source !== "user") return;
        socket.emit("send-changes", delta)
      }

      quill.on("text-change", handler)

      return () => {
        quill.off("text-change", handler)
      }

    }, [socket, quill])

    const wrapperRef = useCallback((wrapper) => {
      if (wrapper == null) return;

      wrapper.innerHTML = "";
      const editor = document.createElement("div")
      wrapper.append(editor)
      const Font = Quill.import("formats/font");
      Font.whitelist = [
        "arial",
        "comic-sans",
        "roboto"
      ];
      // Font.whitelist = [
      //   "arial",
      //   "comic-sans",
      //   "courier-new",
      //   "georgia",
      //   "helvetica",
      //   "lucida",
      //   "roboto"
      // ];
      Quill.register(Font, true);

      const Size = Quill.import("formats/size");
      Size.whitelist = ["extra-small", "small", "medium", "large"];
      Quill.register(Size, true);

      const q = new Quill(editor, 
        { theme: "snow", 
          modules: { 
            toolbar: { container: "#toolbar", handlers: { undo: undoChange, redo: redoChange }}, history: { delay: 500, maxStack: 100, userOnly: true} 
          },
          formats: [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "align",
            "strike",
            "script",
            "blockquote",
            "background",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "code-block"
          ]
        
        } )
      
      
      q.disable()
      q.setText("Loading...")
      setQuill(q)
    }, [])
  return (
    <>
    <EditorToolbar />
    <div className="container" ref={wrapperRef}></div>
    </>
  )
}
