import React from 'react'

export default function WelcomeScreen() {
  return (
    <div>
        <p>Welcome!</p>
        <a href="/create">Create</a>
    </div>
  )
}
