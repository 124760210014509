import React, { useEffect } from 'react'

import { useSocket } from './SocketContext';
import { v4 as uuidV4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

export default function Create() {
    const socket = useSocket();
    const navigate = useNavigate();
    const id = uuidV4();

    useEffect(() => {
        if (socket) {
            socket.emit('create-document', id);
            navigate(`/documents/${id}`, { replace: true })
        }
      }, [socket, navigate, id]);

  return (
    <div>
        <h2>Creating Document...</h2>
    </div>
  )
}
