import React, { useEffect, useRef } from 'react'
import TextEditor from './TextEditor'
import { useState } from 'react'
import { useSocket } from './SocketContext'


export default function Document() {
  const textBox = useRef()
  const [docName, setDocName] = useState("Untitled Document")
  const [localName, setLocalName] = useState("Untitled Document")
  
  useEffect(() => {
    console.log("Running update")
    if (!docName) return
    setLocalName(docName);
  }, [docName])

  const handleChange = (event) => {
    setLocalName(event.target.value)
  }
  function changeDocName(event) {
    event.preventDefault()
    const newName = event.target.value;
    if (newName) {
        setDocName(event.target.value)
    } else {
      setDocName("Untitled Document")
    }
  }


  return (
    <>
        <div className="headingBar">
            {/* <h1>{docName}</h1> */}
            <input type="text" id="nameInput" ref={textBox} value={localName} onChange={handleChange} onBlur={changeDocName} />
        </div>
        <TextEditor docName={docName} setDocName={setDocName} />
    </>
  )
}
