import Document from './Document'
import Error from './Error'
import WelcomeScreen from './WelcomeScreen'
import Create from './Create'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { SocketProvider } from './SocketContext';
import { useEffect, useState } from 'react'


function App() {
  return (
    <SocketProvider>
    <Router>
      <Routes>
        <Route path="/" exact element={<WelcomeScreen />}/>
        <Route path="/create" exact element={<Create />}/>
        <Route path="/error" exact element={<Error/>}/>
          
        <Route path="/documents/:id" element={<Document />} />
      </Routes>
    </Router>
    </SocketProvider>
    )
}

export default App;
