import React from 'react'

export default function Error() {
  const queryParameters = new URLSearchParams(window.location.search)
  const error = queryParameters.get("err")
  return (
    <div>
        <h1>An Error has Occured.</h1>
        <p>Error: {error}</p>
    </div>
  )
}
